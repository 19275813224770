import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Form, Col, Row, Button, InputGroup } from "react-bootstrap";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import logecomApi from "../../utils/axiosInstance";
import AdressesListModal from '../Adresses/AdressesListModal';
import YesNo from '../../utils/YesNo';
import Alerte from '../Alerte';
import DocumentList from '../Document/DocumentList';
import DiscussionModal from '../Discussion/DiscussionModal';
import { downloadCSV, patchFilterFactory } from "../../utils/utils";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Type } from 'react-bootstrap-table2-editor';
import PreReceptionLineEdit from './PreReceptionLineEdit';
import { generateGuid } from '../../utils/utils';

const apiRoot = process.env.REACT_APP_API_ROOT;

const PreReceptionEdit = ({ isModalVisible, editId, fermer, client }) => {
    const [preReception, setPreReception] = useState({});
    const [adresseVisible, setAdresseVisible] = useState(false);
    const [discussionVisible, setDiscussionVisible] = useState(false);
    const [titreElement, setTitreElement] = useState("");
    const [idElementRattache, setIdElementRattache] = useState(0);
    const [messageErreur, setMessageErreur] = useState(null);
    const [documentListVisible, setDocumentListVisible] = useState(false);
    const [preReceptionLineEditVisible, setPreReceptionLineEditVisible] = useState(false);
    const [yesNoDeleteLine, setYesNoDeleteLine] = useState(false);
    const [selectedLine, setSelectedLine] = useState({});
    const [lineData, setLineData] = useState([]);
    const isIE10Mode = document['documentMode'] === 10;
    const [actionWsEnCours, setActionWsEnCoursEnCours] = useState(false);
    const [exportData, setExportData] = useState([]);


    const handleDocumentList = (idPreReception, dateCreation) => {
        if (typeof idPreReception === 'number') {
            setIdElementRattache(idPreReception);
            setTitreElement('Pré-réception du ' + dateCreation)
        }
        setDocumentListVisible(true);
    };

    const setAdresseFournisseur = (adresse) => {
        var libelleFournisseur = adresse.Societe;
        libelleFournisseur += (libelleFournisseur ? ' - ' : '') + adresse.NomPrenom
        setPreReception({ ...preReception, IDCARNET_ADRESSE: adresse.IDCARNET_ADRESSE, LibelleFournisseur: libelleFournisseur })
    };

    const ouvrirDiscussion = (idPreReception, dateCreation) => {
        setIdElementRattache(idPreReception);
        setTitreElement('Pré-réception du ' + dateCreation)
        setDiscussionVisible(true);
    };

    // Définit les contrôles présents sur les sous-lignes
    const lineControlsFormatter = (_, line) =>
        <div className="btn-group">
            <button type="button" title="Modifier" className={"btn btn-md btn-primary"} style={{ marginRight: '5px' }} onClick={() => openLineEditModal(line)}>
                <i className="ion ion-md-create d-block"></i>
            </button>
            <button type="button" title="Supprimer" className={"btn btn-md btn-primary"} style={{ marginRight: '5px' }} onClick={() => { setSelectedLine(line); setYesNoDeleteLine(true) }}>
                <i className="ion ion-md-trash d-block"></i>
            </button>
        </div>;

    const headerWidth = (width) => () => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    };

    const myTableFilterFactory = patchFilterFactory(filterFactory, (filteredData) => {
        setExportData(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(filteredData)) {
                return filteredData
            }
            return prevData
        })
    })

    const loadLinePreReception = () => {
        if (!editId) {
            return;
        }
        logecomApi.get(apiRoot + 'pre_receipts/' + editId + '/lines').then((res) => {
            res.data.forEach(element => {
                element.guid = generateGuid();
            });
            setLineData(JSON.parse(JSON.stringify(res.data)))
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    };

    const loadPreReception = useCallback(() => {
        var url = apiRoot + 'pre_receipts/' + editId;
        logecomApi.get(url).then((res) => {
            let data = res.data[0];
            setPreReception(data);
            loadLinePreReception();
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }, [editId]);

    useEffect(() => {
        if (!Number.isInteger(editId) || !client || parseInt(client) === 0) {
            return;
        }
        if (editId === 0) {
            setPreReception({
                Id_Client: client,
                Lib_BordereauLivraison: "",
                Lib_Transporteur: "",
                IDCARNET_ADRESSE: 0,
                Cloturee: 'Non',
                LibelleFournisseur: "",
                Statut: "Non reçue",
                Commentaire: "",
                Origine: "Pré-enregistrée par le Client",
                CreeLe: "",
                ModifieLe: "",
                DatePrevisionnelle: "",
                nbLignes: 0,
                docExiste: 0,
                Modifiable: 1,
            });
            setLineData([]);
        } else {
            loadPreReception();
        }
    }, [editId, client]);

    const openLineEditModal = (line) => {
        if (line) {
            setSelectedLine(line);
        } else {
            setSelectedLine({
                Id_LignePreReception: 0, SKU: "", Lib_Article1: "", Lib_Article2: "",
                Lib_Famille: "", NumLigne: 0, QuantiteReception: 0,
                Statut: "Non reçue"
            })
        }
        setPreReceptionLineEditVisible(true)
    };

    const editRow = (key, value) => {
        let tempRow = JSON.parse(JSON.stringify(preReception))
        tempRow[key] = value
        setPreReception(tempRow)
    };

    const sauver = () => {
        let tmpPreReception = preReception;
        if (actionWsEnCours) {
            return;
        }
        var datePrevisionnelle = "";
        if (tmpPreReception.DatePrevisionnelle && tmpPreReception.DatePrevisionnelle.length > 0) {
            var parts = tmpPreReception.DatePrevisionnelle.split("/");
            var test = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
            if (isNaN(test) || tmpPreReception.DatePrevisionnelle.length < 10) {
                setMessageErreur("La date de réception prévisionnelle n'est pas valide. Doit être au format JJ/MM/AAAA exemple 24/12/2023")
                return;
            } else {
                datePrevisionnelle = parts[2] + parts[1] + parts[0];
            }
        }
        setActionWsEnCoursEnCours(true);


        if (editId === 0) {
            var row = {
                Entete: {
                    idclient: client,
                    Transporteur: tmpPreReception.Lib_Transporteur,
                    Commentaire: tmpPreReception.Commentaire,
                    BordereauLivraison: tmpPreReception.Lib_BordereauLivraison,
                    IDCARNET_ADRESSE: tmpPreReception.IDCARNET_ADRESSE
                },
                Lignes: lineData.map(elt => {
                    return {
                        QuantiteReception: elt.QuantiteReception,
                        idarticle: elt.idarticle
                    }
                })
            }

            if (datePrevisionnelle !== "") {
                row.Entete.DatePrevisionnelle = datePrevisionnelle;
            }

            logecomApi.post(apiRoot + 'pre_receipts', row).then((res) => {
                setActionWsEnCoursEnCours(false);
                editId = parseInt(res.data.ressource_Created.split(/[/ ]+/).pop());
                fermer();
            }).catch((error) => {
                setActionWsEnCoursEnCours(false);
                alert(error.response.data.fault.detail)
            })
        } else {
            const rowToSend = {
                BordereauLivraison: tmpPreReception.Lib_BordereauLivraison,
                Transporteur: tmpPreReception.Lib_Transporteur,
                Commentaire: tmpPreReception.Commentaire,
                DatePrevisionnelle: datePrevisionnelle,
                IDCARNET_ADRESSE: tmpPreReception.IDCARNET_ADRESSE
            }

            logecomApi.put(apiRoot + 'pre_receipts/' + editId, rowToSend).then(() => {
                setActionWsEnCoursEnCours(false);
                fermer();
            }).catch((error) => {
                setActionWsEnCoursEnCours(false);
                setMessageErreur(error.response.data.fault.detail)
            })
        }
    };

    const deleteLine = (line) => {
        if (editId === 0) {
            setLineData(lineData.filter(elt => elt.Id_LignePreReception !== line.Id_LignePreReception))
        } else {
            logecomApi.delete(apiRoot + 'pre_receiptsline/' + line.Id_LignePreReception).then(res => {
                loadLinePreReception();
            }).catch((error) => {
                setMessageErreur(error.response.data.fault.detail)
            })
        }
    };

    const controlsFormatterLigne = (cell, row, rowIndex, nomColonne) => {
        if (row.QuantiteDejaReçue === 0) {
            return (<div style={{ color: 'red' }}>{cell}</div>)
        } else if (row.QuantiteDejaReçue && row.QuantiteReception !== row.QuantiteDejaReçue) {
            return (<div style={{ color: 'darkOrange' }}>{cell}</div>)
        } else {
            return (
                cell
            )
        }
    }

    // La 1ère valeur est l'en-tête de colonne, la 2ème le nom de la propriété renvoyée par l'API, la 3ème la largeur de la colonne en pixels  
    const lineColumns =
        [
            ["", "", 85, false],
            ["ID", "Id_LignePreReception", 0, false], ["SKU", "SKU", 150, true], ["Libellé", "Lib_Article1", 150, true],
            ["Libellé complémentaire", true, "Lib_Article2", 150, true],
            ["Famille", "Lib_Famille", 120, true], ["N° Ligne", "NumLigne", 90, true],
            ["Qté", "QuantiteReception", 60, true], ["Qté reçues", "QuantiteDejaReçue", 60, true],
            ["Statut", "Statut", 180, true], ["guid", "guid", 0, false]
        ]
            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], exporter: elt[3], headerStyle: headerWidth(elt[2]), filter: textFilter({ placeholder: elt[0] }), sort: true }
                result.formatExtraData = result.dataField;
                result.formatter = controlsFormatterLigne;
                if (result.text === "") {
                    result.isDummyField = true
                    result.CSVExport = false
                    result.formatter = lineControlsFormatter
                    result.sort = false
                    result.filter = undefined
                }
                if (["ID", "guid"].includes(result.text)) {
                    result.hidden = true
                }
                if (result.text === "Statut") {
                    result.filter = selectFilter({
                        options: {
                            'Reçue': 'Reçue',
                            'Non reçue': 'Non reçue',
                        },
                        className: 'custom-select',
                        placeholder: 'Non reçue',
                    })
                    result.editor = {
                        type: Type.SELECT,
                        options: [
                            { value: 'Reçue', label: 'Reçue' },
                            { value: 'Non reçue', label: 'Non reçue' },
                        ]
                    }
                }
                return result
            })


    return (
        <>
            <Modal show={isModalVisible} backdrop="static" onHide={fermer} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">
                        Fiche Pré-réception <br />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={6}>
                                <strong>Statut :&nbsp; </strong>
                                {preReception.Statut}
                            </Col>
                            <Col sm={6}>
                                <strong>Origine :&nbsp;</strong>
                                {preReception.Origine}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={6}>
                                <strong>Créé le :&nbsp;</strong>
                                {preReception.CreeLe}
                            </Col>
                            <Col sm={6}>
                                <strong>Modifié le :&nbsp;</strong>
                                {preReception.ModifieLe}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={6}>
                                <strong>
                                    Bordereau :
                                </strong>
                                <Form.Control type="text" maxLength="50" value={preReception.Lib_BordereauLivraison} onChange={e => editRow("Lib_BordereauLivraison", e.target.value)} />
                            </Col>
                            <Col sm={6}>
                                <strong>
                                    Transporteur :
                                </strong>
                                <Form.Control type="text" maxLength="30" value={preReception.Lib_Transporteur} onChange={e => editRow("Lib_Transporteur", e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={6}>
                                <strong>
                                    Réception prévue le :
                                </strong>
                                <Form.Control type="text" placeholder="JJ/MM/AAAA" maxLength="10" value={preReception.DatePrevisionnelle} onChange={e => editRow("DatePrevisionnelle", e.target.value)} />
                            </Col>
                            <Col sm={6}>
                                <strong>
                                    Fournisseur :
                                </strong>
                                <InputGroup>
                                    <Form.Control type="text" style={{ marginRight: '5px' }} disabled={true} value={preReception.LibelleFournisseur} />
                                    <button type="button" title="Choisir dans le carnet d'adresse" className="btncommande btn-sm btn-primary" onClick={() => setAdresseVisible(true)}>
                                        <i className="ion ion-md-search d-block"></i>
                                    </button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                    <div className="section-form">
                        <Row className="mb-2">
                            <Col sm={2}>
                                <Form.Label>
                                    Commentaire :
                                </Form.Label>
                            </Col>
                            <Col sm={10}>
                                <Form.Control as="textarea" rows="3" maxLength="4000" value={preReception.Commentaire} onChange={e => editRow("Commentaire", e.target.value)} />
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginBottom: '40px' }}>
                        <button type="button" className="btn btn-md btn-primary btn-plus-modal" style={{ marginRight: '5px' }} onClick={() => openLineEditModal(null)}>
                            <i className="ion ion-md-add-circle d-block"></i>
                        </button>
                    </div>
                    {isIE10Mode && <div className="alert alert-danger">
                        <strong>react-bootstrap-table2</strong> doesn't support Internet Explorer 10
                    </div>}
                    {!isIE10Mode && <React.Fragment>
                        <ToolkitProvider
                            keyField="Id_LignePreReception"
                            data={lineData}
                            columns={lineColumns}
                            bootstrap4
                            search
                            columnToggle
                            exportCSV
                        >
                            {props => (
                                <div className="relative btn-export-wrapper">
                                    <Button variant="default" className="blue" onClick={() => { downloadCSV(exportData, lineColumns, 'Lignes-Pre-Reception') }}>Export CSV</Button>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        wrapperClasses="table-responsive"
                                        pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 20 })}
                                        filter={myTableFilterFactory()}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </React.Fragment>}
                    <Row>
                        <Col>
                            Les lignes <strong style={{ color: 'red' }} >en rouge</strong> indiquent des articles non reçus.
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Les lignes <strong style={{ color: 'orange' }} >en orange</strong> indiquent des quantités reçues différentes de celles attendues.
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {editId > 0 && <Button variant="default" className="blue" onClick={() => { ouvrirDiscussion(preReception.id_PreReception, preReception.CreeLe) }}>Discussions</Button>}
                    {editId > 0 && <Button variant="default" className="blue" onClick={() => { handleDocumentList(preReception.id_PreReception, preReception.CreeLe) }}>Documents</Button>}
                    <Button className="blue" variant="default" onClick={sauver}>Valider</Button>
                    <Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
                </Modal.Footer>
            </Modal >

            <YesNo show={yesNoDeleteLine} hide={setYesNoDeleteLine} callbackfunction={deleteLine} object={selectedLine} title="Suppression" body="Confirmez-vous la suppression de cette ligne de pré-réception"></YesNo>

            <Alerte
                isModalVisible={messageErreur}
                handleAlerte={() => setMessageErreur(null)}
                message={messageErreur}
            />
            <DocumentList
                isModalVisible={documentListVisible}
                elementRattache='Pre-Receipt'
                idElementRattache={idElementRattache}
                client={client}
                titre={titreElement}
                handleDocumentList={() => setDocumentListVisible(!documentListVisible)}
            />
            <DiscussionModal
                isModalVisible={discussionVisible}
                elementRattache='Pre-Receipt'
                idElementRattache={idElementRattache}
                client={client}
                titre={titreElement}
                fermerDiscussion={() => setDiscussionVisible(false)}
            />
            <AdressesListModal
                visible={adresseVisible}
                fermer={() => setAdresseVisible(false)}
                client={client}
                natureAdresse={2}
                idBoutique={0}
                setAdresse={setAdresseFournisseur}
            />
            <PreReceptionLineEdit
                isModalVisible={preReceptionLineEditVisible}
                fermer={() => {
                    setSelectedLine(null);
                    setPreReceptionLineEditVisible(false);
                }}
                selectedLine={selectedLine}
                newRow={selectedLine && selectedLine.SKU !== "" ? false : true}
                idpre_reception={editId}
                client={client}
                valider={((lineToSend) => {
                    let _lines = JSON.parse(JSON.stringify(lineData));
                    if (editId > 0) {
                        loadLinePreReception();
                    } else if (!selectedLine.guid) {
                        lineToSend.guid = generateGuid();
                        _lines.push(lineToSend);
                        setLineData(_lines);
                    } else {
                        const index = _lines.findIndex(line => line.guid === lineToSend.guid);
                        _lines[index] = lineToSend;
                        setLineData(_lines);
                    }
                    setSelectedLine(null);
                    setPreReceptionLineEditVisible(false);
                })}
            />
        </>
    );
}

export default PreReceptionEdit;