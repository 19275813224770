import React, { useEffect, useState } from 'react';
import Login from './Components/Login';
import Articles from './Components/Articles/Articles'
import Dashboard from './Components/Dashboard';
import Prereception from './Components/Pre-Reception/PreReceptionsPage';
import Reception from './Components/Reception/ReceptionsPage';
import Commande from './Components/Commandes/Commande';
import TarifTransporteur from './Components/TarifTransporteur';
import DiscussionsPage from './Components/Discussion/DiscussionsPage';
import FacturesPage from './Components/Factures/FacturesPage';
import AdressesPage from './Components/Adresses/AdressesPage';
import TarifConsommablesPage from './Components/Articles/TarifConsommablesPage';
import Layout from './Components/Layout';
import Lots from './Components/Lots/LotsPage';
import SavPage from './Components/Sav/SavPage';
import ChangeMdp from './Components/ChangeMdp';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const apiRoot = process.env.REACT_APP_API_ROOT

const App = () => {
  const [client, setClient] = useState(0);
  const [idBoutique, setIdBoutique] = useState(0);

  useEffect(() => {
    const idClient = localStorage.getItem("user_gd") === "1" ? localStorage.getItem("selected_client") : localStorage.getItem("id_client")
    setClient(idClient ? parseInt(idClient) : 0);
    const idBoutique = localStorage.getItem("id_boutique" + idClient);
    setIdBoutique(idBoutique ? parseInt(idBoutique) : 0);
    if (parseInt(localStorage.getItem('changement_mdp')) === 1 && !window.location.href.endsWith('/changemdp') && !window.location.href.endsWith('/login')) {
      window.location = "/changemdp";
    }
    else if (window.location.href.includes('/resetpassword')) {
      let param = window.location.href.split("/").pop();
      const encoded = btoa(param);
      const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'default'
      };
      const myRequest = new Request(apiRoot + 'resetpassword/2/' + encoded, options);
      localStorage.setItem('mdpEnvoye', 1)
      fetch(myRequest, options)
        .then(response => {
          if (!response.ok) {
            throw new Error("erreur HTTP! statut: " + response.status);
          } else {
            window.location = "/login";
            //throw new Error("Un mot de passe provisoire vient d'être généré. Vérifiez votre boîte de réception pour les instructions.");
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    }

  }, []);

  /*
   useEffect(() => {
     if (idBoutique < 1) {
       return;
     }
     // On recharge les droits acces
     logecomApi.get(apiRoot + 'usersettings/' + idBoutique + '/accesboutique').then((res) => {
       let data = res.data[0];
       if (localStorage.getItem("user_gd") === "1") {
         localStorage.setItem("droitsAcces", 0);
       } else if (data.Restriction) {
         localStorage.setItem("droitsAcces", data.Restriction);
       } else {
         localStorage.setItem("droitsAcces", 2); // 2 = aucun droit
       }
     }).catch((error) => {
       console.log(JSON.stringify(error))
     })
   }, [idBoutique]);
 */
  return (
    <>
      <Router forceRefresh={true}>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
        </Switch>
        <Layout>
          <Switch>
            <Route exact path="/changemdp">
              <ChangeMdp />
            </Route>
            <Route exact default path="/">
              <Dashboard client={client} />
            </Route>
            <Route exact path="/articles">
              <Articles client={client} />
            </Route>
            <Route exact path="/pre-reception">
              <Prereception client={client} />
            </Route>
            <Route exact path="/reception">
              <Reception client={client} />
            </Route>
            <Route exact path="/commande">
              <Commande client={client} idBoutique={idBoutique} />
            </Route>
            <Route exact path="/tarif-transporteur">
              <TarifTransporteur client={client} />
            </Route>
            <Route exact path="/discussions">
              <DiscussionsPage client={client} />
            </Route>
            <Route exact path="/factures">
              <FacturesPage client={client} />
            </Route>
            <Route exact path="/adresses">
              <AdressesPage client={client} idBoutique={idBoutique} />
            </Route>
            <Route exact path="/tarifconsommables">
              <TarifConsommablesPage client={client} />
            </Route>
            <Route exact path="/lots">
              <Lots client={client} />
            </Route>
            <Route exact path="/sav">
              <SavPage client={client} />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </>
  );
}


export default App;
