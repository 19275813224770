import React, { useEffect, useState } from 'react';
import logecomApi from "../../utils/axiosInstance";
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { textFilter } from 'react-bootstrap-table2-filter'
import { Modal, Form, Col, Row, Button } from "react-bootstrap"
import Alerte from '../Alerte';
import LotsByItem from '../Lots/LotsByItem';
import { nullOrEmpty } from '../../utils/utils';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import { dateVersChaine } from '../../utils/utils';

const apiRoot = process.env.REACT_APP_API_ROOT

const ValorisationEdit = ({ idarticle, client, fermer }) => {
    const [tabvalorisation, setTabValorisation] = useState([]);
    const [valorisation, setValorisation] = useState({});
    const [article, setArticle] = useState({});
    const [actionWsEnCours, setActionWsEnCoursEnCours] = useState(false);
    const [alerte, setAlerte] = useState(null);
    const [coutDansLot, setCoutDansLot] = useState(false);
    const [lotsByItem, setLotsByItemVisible] = useState(false);

    const supprimer = (row) => {
        if (actionWsEnCours) {
            return;
        }
        setActionWsEnCoursEnCours(true);
        logecomApi.delete(apiRoot + 'items/' + row.IDVALORISATION_ARTICLE + '/valorisation').then(() => {
            setActionWsEnCoursEnCours(false);
            loadTabValorisation();
        }).catch((error) => {
            setActionWsEnCoursEnCours(false);
            setAlerte(error.response.data.fault.detail)
        })
    };

    const sauver = () => {
        if (actionWsEnCours) {
            return;
        }
        var dateValorisation = "";
        if (valorisation.DateValorisation && valorisation.DateValorisation.length > 0) {
            var parts = valorisation.DateValorisation.split("/");
            var test = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
            if (isNaN(test) || valorisation.DateValorisation.length < 10) {
                setAlerte("La date de valorisation n'est pas valide");
                return;
            } else {
                dateValorisation = parts[2] + parts[1] + parts[0];
            }
        }
        if (dateValorisation === '' || !valorisation.CoutAcquisition) {
            setAlerte("La date de valorisation et le montant doivent être renseignés");
            return;
        }
        setActionWsEnCoursEnCours(true);

        var row = {
            DateValorisation: dateValorisation,
            CoutAcquisition: valorisation.CoutAcquisition,
        }

        logecomApi.post(apiRoot + 'items/' + idarticle + '/valorisation', row).then(() => {
            setActionWsEnCoursEnCours(false);
            setValorisation({ DateValorisation: '', CoutAcquisition: '' });
            loadTabValorisation();
        }).catch((error) => {
            setActionWsEnCoursEnCours(false);
            setAlerte(error.response.data.fault.detail)
        })
    };

    const loadTabValorisation = () => {
        logecomApi.get(apiRoot + 'items/' + idarticle + '/valorisation').then((res) => {
            setTabValorisation(res.data)
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })
    }

    useEffect(() => {
        if (!idarticle || idarticle < 1) {
            return;
        }
        setValorisation({ DateValorisation: dateVersChaine(new Date(), '/'), CoutAcquisition: '' });
        loadTabValorisation();
        logecomApi.get(apiRoot + 'items/' + idarticle).then((res) => {
            setArticle(res.data[0])
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

        logecomApi.get(apiRoot + 'items/' + client + '/' + idarticle + '/lots').then((res) => {
            let coutDansLot = false;
            res.data.forEach(lot => {
                if (lot.CoutAcquisition > 0) {
                    coutDansLot = true;
                }
            });
            setCoutDansLot(coutDansLot);
        }).catch((error) => {
            console.log(JSON.stringify(error))
        })

    }, [idarticle, client]
    );

    const editRow = (key, value) => {
        let temp = JSON.parse(JSON.stringify(valorisation))
        temp[key] = value
        setValorisation(temp)
    };

    const filteredData = () => {
        if (nullOrEmpty(tabvalorisation)) {
            return [];
        } else {
            return tabvalorisation;
            /* return tabvalorisation.filter(function (row) {
                 let affiche = true;
                 return affiche;
             })*/
        }
    }

    const headerWidth = (width) => (colum, colIndex) => {
        return { width: width + 'px', fontSize: '12.5px', lineHeight: '14px' };
    };

    const rowStyle = (row, rowIndex) => {
        if (rowIndex % 2 === 1) {
            return { backgroundColor: '#dddddd' };
        }
    };

    const controlsFormatter = (cell, row, rowIndex, nomColonne) => {
        if (nomColonne === 'ICONS') {
            return (
                <div className="btn-group">
                    <button type="button" title="Supprimer" className="btncommande btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => { supprimer(row); }}>
                        <i className="ion ion-md-trash d-block"></i>
                    </button>

                </div>

            )
        } else if (nomColonne === 'CoutAcquisition') {
            let val = cell;
            if (val === 0) {
                return '';
            } else {
                val = val.toFixed(2);
                val = val.replace(".", ",") + ' €';
                return (
                    val
                )
            }
        }
        else {
            if (cell === 0) {
                return ('')
            } else {
                return (cell)
            }
        }
    }

    const columns =
        [
            ["", "ICONS", 20, true, false],
            ["ID", "IDVALORISATION_ARTICLE", 180, false, false],
            ["IDARTICLE", "IDARTICLE", 180, false, false],
            ["Date valorisation", "DateValorisation", 40, true, true],
            ["Montant valorisation", "CoutAcquisition", 60, true, true],
        ]

            .map(elt => {
                let result = { text: elt[0], dataField: elt[1], visible: elt[3], exporter: elt[4], headerStyle: headerWidth(elt[2]), filter: textFilter(), sort: true }
                result.formatExtraData = result.dataField;
                result.formatter = controlsFormatter
                result.filter = undefined

                if (result.visible === false) {
                    result.hidden = true;
                };
                if (result.dataField === "") {
                    result.isDummyField = true
                    result.CSVExport = false
                    result.sort = false
                    result.filter = undefined
                };

                if (["QuantiteEnStock", "QuantiteReceptionne", "CoutAcquisition"].includes(result.dataField)) {
                    result.style = { textAlign: 'right', verticalAlign: 'middle' };
                };

                return result
            })

    return (
        <>
            <div>
                <Modal show={idarticle} backdrop="static" onHide={fermer} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Fiche Valorisation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="section-form">
                            <Row className="mb-2">
                                <Col sm={4}>
                                    <strong>Code SKU :&nbsp; </strong>
                                </Col>
                                <Col sm={8}>
                                    {article.CodeSKU}
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm={4}>
                                    <strong>Libellé :&nbsp; </strong>
                                </Col>
                                <Col sm={8}>
                                    {article.LibelleArticle1}
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm={4}>
                                    <strong>Libellé complémentaire :&nbsp; </strong>
                                </Col>
                                <Col sm={8}>
                                    {article.LibelleArticle2}
                                </Col>
                            </Row>
                        </div>
                        <div className="section-form">
                            <Row className="mb-2">
                                <Col sm={2} style={{ textAlign: 'right' }}>
                                    <strong>
                                        Date :
                                    </strong>
                                </Col>
                                <Col sm={3}>
                                    <Form.Control type="text" placeholder="JJ/MM/AAAA" maxLength="10" value={valorisation.DateValorisation} onChange={e => editRow("DateValorisation", e.target.value)} />
                                </Col>
                                <Col sm={2}>
                                    <Form.Label>
                                        <strong>Montant de valorisation :</strong>
                                    </Form.Label>
                                </Col>
                                <Col sm={2}>
                                    <Form.Control type="number" min="1" step="any" value={valorisation.CoutAcquisition} onChange={e => editRow("CoutAcquisition", e.target.value)} />
                                </Col>
                                <Col sm={2}>
                                    <Button variant="default" className="blue" onClick={sauver}>Ajouter</Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="section-form">
                            <Row className="mb-2">
                                <Col sm={2}></Col>
                                <Col sm={8}>
                                    <ToolkitProvider
                                        keyField="IDVALORISATION_ARTICLE"
                                        data={filteredData()}
                                        columns={columns}
                                        bootstrap4
                                        search
                                        columnToggle
                                    >
                                        {props => (
                                            <div>
                                                {<Row style={{ marginBottom: '20px' }}>
                                                    <Col>
                                                        <Form.Label>
                                                            <strong>Historique</strong>
                                                        </Form.Label>
                                                    </Col>
                                                </Row>}
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    rowStyle={rowStyle}
                                                    wrapperClasses="table-responsive"
                                                    pagination={paginationFactory({ sizePerPageList: [20, 50, 100, 250], sizePerPage: 50 })}
                                                />
                                            </div>

                                        )}
                                    </ToolkitProvider>
                                </Col>
                            </Row>
                        </div>
                        {coutDansLot &&
                            <div className="section-form">
                                <Row className="mb-2">
                                    <Col sm={7}>
                                        <div className="form-group">
                                            <strong style={{ color: 'red' }}>Certains lots de cet article possède de la valorisation</strong>
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <Button variant="default" className="blue" onClick={() => { setLotsByItemVisible(true) }}>Lots</Button>
                                    </Col>
                                </Row>
                            </div>
                        }

                    </Modal.Body >
                    <Modal.Footer>
                        <Button variant="default" className="blue" onClick={fermer}>Fermer</Button>
                    </Modal.Footer>
                </Modal >
            </div >


            <LotsByItem
                isModalVisible={lotsByItem}
                fermer={() => setLotsByItemVisible(false)}
                client={client}
                articleId={idarticle}
                titre={"Liste des lots de l'article : " + article.CodeSKU + " - " + article.LibelleArticle1}
                modeSelection={false}
            />

            <Alerte
                isModalVisible={alerte}
                handleAlerte={() => setAlerte(null)}
                message={alerte}
            />

        </>
    );
}
export default ValorisationEdit;